import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import { Helmet } from 'react-helmet';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Pagination from '../Pagination';
import PostStyle2 from '../Post/PostStyle2';
import Div from '../Div';
import Sidebar from '../Sidebar.jsx';
import Spacing from '../Spacing';
const postData = [
  {
    thumb: '/images/post_4.jpeg',
    title: 'A.I will take all human job within next year',
    subtitle:
      'Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Tortor posuere ac ut consequat semper viverra nam libero justo. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna. Purus sit amet luctus venenatis lectus. Nunc aliquet bibendum enim facilisis. Pretium viverra suspendisse potenti nullam ac tortor vitae.',
    date: '07 Mar 2024',
    category: 'Tech',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
  {
    thumb: '/images/post_5.jpeg',
    title: 'The Future of Full Stack Development',
    subtitle:
      'Full Stack Development is evolving rapidly, blending frontend and backend technologies into seamless solutions. This blog explores upcoming trends, including serverless architectures, the rise of frameworks like Next.js for React, and the increasing importance of cybersecurity in the full stack domain.',
    date: '01 Mar 2024',
    category: 'Photography',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
  {
    thumb: '/images/post_6.jpeg',
    title: 'Building Interactive UIs with React: A Comprehensive Guide',
    subtitle:
      'React has revolutionized UI development. This guide dives into creating dynamic and interactive user interfaces with React, utilizing hooks, context, and advanced state management techniques to build highly responsive applications.',
    date: '08 Fev 2024',
    category: 'Tech',
    categoryHref: '/blog',
    href: '/blog/blog-details',
  },
];

export default function BlogPage() {
  pageTitle('Blog');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
     <Helmet>
        <title>Our Blog - Latest Insights and Trends</title>
        <meta name="description" content="Explore our blog for the latest insights and trends in full stack development, SEO, React, open AI response streaming, and JavaFX. Stay ahead with our expert analysis and tips." />
        <meta name="keywords" content="full stack development, SEO, React, open AI, JavaFX, tech trends, programming, web development" />
        <link rel="canonical" href="https://www.yoursite.com/blog" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <PageHeading
        title="Our Blog"
        bgSrc="/images/blog_hero_bg.jpeg"
        pageLinkText="Blog"
      />
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-8">
            {postData.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  thumb={item.thumb}
                  title={item.title}
                  subtitle={item.subtitle}
                  date={item.date}
                  category={item.category}
                  categoryHref={item.categoryHref}
                  href={item.href}
                />
                {postData.length > index + 1 && <Spacing lg="95" md="60" />}
              </Div>
            ))}
            <Spacing lg="60" md="40" />
            <Pagination />
          </Div>
          <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Sidebar />
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
