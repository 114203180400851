import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Accordion from '../Accordion';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
// import LogoList from '../LogoList';
import { Helmet } from 'react-helmet';

import MovingText from '../MovingText';
// import MovingText2 from '../MovingText/MovingText2';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
// import TeamSlider from '../Slider/TeamSlider';
// import VideoModal from '../VideoModal';
import ServiceList from '../ServiceList';
// import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
// Hero Social Links
const heroSocialLinks = [
  {
    name: 'Linkedin',
    links: '/',
  },
  {
    name: 'Twitter',
    links: '/',
  },
];

// const serviceData1 = [
//   {
//     title: 'UI/UX Design',
//     href: '/service/service-details',
//   },
//   {
//     title: 'Marketing',
//     href: '/service/service-details',
//   },
//   {
//     title: 'Branding',
//     href: '/service/service-details',
//   },
// ];
// const serviceData2 = [
//   {
//     title: 'SEO',
//     href: '/service/service-details',
//   },
//   {
//     title: 'App design',
//     href: '/service/service-details',
//   },
//   {
//     title: 'React Developer',
//     href: '/service/service-details',
//   },
// ];
// FunFact Data
const funfactData = [
  {
    title: 'Happy Clients',
    factNumber: '5+',
  },
  {
    title: 'Lines of Code Written',
    factNumber: '10K+',
  },
  {
    title: 'Successful Projects Delivered',
    factNumber: '15',
  },
  {
    title: 'Cups of Coffee',
    factNumber: '100+',
  },
];

const portfolioData = [
  {
    title: 'React E-commerce Platform',
    subTitle: "See Details" ,
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_1.jpeg',
  },
  {
    title: 'UI/UX Design for Mobile App',
    subTitle: "See Details" ,
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_2.jpeg',
  },
  {
    title: 'Email Marketing Automation',
    subTitle: "See Details" ,
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_0.jpg',
  },
  {
    title: 'Professional Portfolio Website',
    subTitle: "See Details" ,
    href: '/portfolio/portfolio-details',
    src: '/images/portfolio_3.jpeg',
  }
];

export default function Home() {
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <Helmet>
        <title>Talso - Bridging Business and Technology</title>
        <meta name="description" content="At Talso, we specialize in advanced, customized software solutions to bridge the gap between business and technology. Explore our services in full-stack development, React projects, UI/UX design, and more." />
        <meta name="keywords" content="software development, React, UI/UX design, full-stack development, technology solutions, digital agency" />
        {/* Add any other SEO relevant tags here */}
      </Helmet>
      {/* Start Hero Section */}
      <Hero
        title="Shaping the,<br/> Future of Tech"
        subtitle="Bridging the Gap Between Business and Technology with Advanced, Customized Software Solutions for the Modern Era."
        btnText="Let's Talk"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="Did you know? The first computer bug was an actual bug—a moth trapped in Harvard Mark -II computer in 1947!"
          data={funfactData}
        />
      </div>
      {/* End FunFact Section */}
      {/* <Spacing lg="70" md="80" /> */}
      {/* Start Moving Text Section */}



      {/* <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} /> */}

      <Spacing lg="70" md="70" />
      <MovingText text="Seamlessly Integrating Tomorrow's Technology into Today's Business Strategies" />
      <Spacing lg="75" md="70" />


      {/* End Moving Text Section */}
      {/* Start Service Section */}
      {/* <Spacing lg="70" md="80" /> */}
      <Div id="service">
        <Div className="container pcView">
          <SectionHeading
            title="Our core services"
            subtitle="Services"
            variant="cs-style1 text-center"
          />
          <Spacing lg="70" md="45" />
          <ServiceList />
        </Div>
        <Div className="container mobView">

          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
              />

              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Chatbot Development"
                    link="/service/service-details"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 mm"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="React.js Development"
                    link="/service/service-details"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="UI/UX Design"
                    link="/service/service-details"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Email Automation"
                    link="/service/service-details"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>

          </Div>

        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}



      <Spacing lg="100" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider data={portfolioData} />
      </Div>
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      <Spacing lg="100" md="70" />
      {/* <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
         What our Clients say about us
        </h2>
         <Spacing lg="20" md="70" /> */}
      {/* <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />*/}
      {/* </Div>  */}
      {/* End Video Block Section */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Blog Section */}
      <Spacing lg="155" md="70" />
      {/* Start MovingText Section */}
      {/* 
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="105" md="70" /> */}
      {/* End MovingText Section */}

      {/* Start LogoList Section */}

      {/* <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      <Spacing lg='90' md='45' />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      <Spacing lg='90' md='45' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='90' md='45' />
    </>
  );
}
