import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Post from '../Post';
const postData = [
  {
    url: '/blog/blog-details',
    src: '/images/post_1.jpeg',
    alt: 'Post',
    date: '25 Feb 2024', // Updated to a more recent date
    title: 'React Development: Trends and Best Practices for 2024',
  },
  {
    url: '/blog/blog-details',
    src: '/images/post_2.jpeg',
    alt: 'Post',
    date: '18 Feb 2024', // Updated to a more recent date
    title: 'The Future of UI/UX: Designing for User Delight',
  },
  {
    url: '/blog/blog-details',
    src: '/images/post_3.jpeg',
    alt: 'Post',
    date: '11 Feb 2024', 
    title: 'Maximizing ROI with Effective Email Automation Strategies',
  },
  {
    url: '/blog/blog-details',
    src: '/images/post_1.jpeg',
    alt: 'Post',
    date: '04 Feb 2024', 
    title: 'Building a Stunning Portfolio Site: Tips and Tricks',
  },
  {
    url: '/blog/blog-details',
    src: '/images/post_2.jpeg',
    alt: 'Post',
    date: '28 Jan 2024', 
    title: 'Integrating React with Other Technologies for Dynamic Web Apps',
  },
  {
    url: '/blog/blog-details',
    src: '/images/post_3.jpeg',
    alt: 'Post',
    date: '21 Jan 2024', 
    title: 'Creating Engaging Experiences with Advanced UI/UX Design',
  },
];


export default function PostSlider() {
  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            date={item.date}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
